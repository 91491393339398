<template>
  <div class="tickets-addition">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="20vh"
               class="tickets-addition-dialog">
      <div slot="title"
           class="tickets-addition-form__header">
        <span>{{title}}</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="tickets-addition-form__body">
          <el-form-item label="类型名称"
                        prop="typeName">
            <el-input v-model="form.typeName"
                      placeholder="请输入类型名称"
                      maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="选择模板"
                        prop="functionType" class="role">
               <el-radio-group v-model="form.functionType">
                  <el-radio :label="item" v-for="(item,index) in functionType" :key="index"  class="m-radio" name="functionType">{{item}}</el-radio>
                </el-radio-group>
          </el-form-item>
          <el-form-item label="票券转赠"
                        prop="donor"
                      >
            <template >
                <el-checkbox-group v-model="form.donor"  >
                  <el-checkbox :label="t.id" v-for="(t, index) in donor" :key="index" name="donor" class="m-checkbox">{{t.name}}</el-checkbox>
                </el-checkbox-group>
            </template>
          </el-form-item>
           <el-form-item label="票券详情" prop="detail">
            <template >
                <el-checkbox-group v-model="form.detail"  >
                  <el-checkbox :label="t.id" v-for="(t, index) in detail" :key="index" name="detail" class="m-checkbox">{{t.name}}</el-checkbox>
                </el-checkbox-group>
            </template>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="tickets-addition-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('form')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
// import timeFormat from '@/utils/timeFormat.js'

export default {
  props: {
    title: {
      type: String,
      default: () => {
        return '新增类型'
      }
    }
  },
  data() {
    return {
      // 新建的票券类别相关信息
      form: {
        typeName: '',
        functionType: '普通券',
        donor: [],
        detail: []
      },
      options: [],
      // 校验规则
      rules: {
        typeName: [ { validator: (rule, val, callback) => {
          if (val) {
            callback()
          } else {
            callback(new Error('请输入类型名称'))
          }
        }
        }]
      },
      dialogWidth: '0',
      visible: false,
      loading: false,
      detail: [],
      donor: [],
      functionType: []
    }
  },
  mounted() {
    this.clearForm()
    this.visible = true
    this.setDialogWidth()
    this.getTags()
    // 监听浏览器窗口大小变化，用于新增记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  // 移除事件监听
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    },
    'form.functionType'(newVal, old) {
      this.change(newVal)
    }
  },
  methods: {
    change (newVal) {
      if (newVal === '非遗券') {
        this.form.detail = ['103']
        this.form.donor = ['201', '202', '203']
      } else if (newVal === '普通券' || newVal === '饮料券') {
        this.form.detail = []
        this.form.donor = []
      } else if (newVal === '中奖券' || newVal === '抽奖券') {
        this.form.donor = ['201', '202', '203']
        this.form.detail = []
      }
    },
    // 清空窗口的输入内容
    clearForm() {
      this.form.typeName = ''
      this.form.donor = []
      this.form.detail = []
      this.form.functionType = '普通券'
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = deepClone(this.form)
          console.log(data)
          this.$emit('create', data)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '550px'
      }
    },
    // 获取权限列表
    getTags() {
      this.$http.ticketType.getTicketTypeTags().then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.functionType = res.data.data.functionType
          this.donor = res.data.data.donor
          this.detail = res.data.data.detail
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }

  }
}
</script>

<style lang="scss">
.tickets-addition-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 24px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.tickets-addition {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 292px;
        }
        .el-date-editor {
          width: 260px;
          .el-range-separator {
            width: 12%;
            font-weight: 600;
            padding: 0;
          }
        }
      }
      .ticketType {
        margin-top: 68px;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
    &__info {
      position: absolute;
      // top: 410px;
      // left: 150px;
      top: 409px; // 非遗票券选择存在时使用
      left: 150px; // 非遗票券选择存在时使用
      user-select: none;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-extra-small;
        margin-right: 40px;
        color: #b3b4ba;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 10px 20px;
    }
  }
  .tickets-addition-form__info {
    // top: 400px;
    // left: 48px;
    top: 402px; // 非遗票券选择存在时使用
    left: 48px; // 非遗票券选择存在时使用
  }
}

@media screen and (max-width: 375px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 200px;
        }
        .el-date-editor {
          width: 200px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
}

@media screen and (max-width: 320px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 183px;
        }
        .el-date-editor {
          width: 183px;
        }
      }
    }
  }
}
.m-checkbox{
  width:30%;
  margin:0 0 !important;
  // &:first-child{
  //   margin-left: 30px;
  // }
}
.m-radio{
  width:25%;
  margin:10px 10px 10px 0 !important;

}
</style>
