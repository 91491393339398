<template>
  <div class="tickets">
    <div class="title">类型管理</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   enableOperatorSlot
                   operatorWidth="150px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 顶部右侧搜索，刷新和新增 -->
          <div class="top-control-add">
            <el-button type="primary"
                       @click="showAdditionFn">新增</el-button>
          </div>
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索类型名称"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
      </template>
       <template #tags="{row}">
         <template >
              {{row.tags.join('，')}}
         </template>
      </template>

      <template #createTime="{row}">
        {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn el-icon-edit"
                   @click.stop="showDetail(row)" v-if="!row.used">修改</el-button>

        <!-- 测试用 -->
        <el-button class="operator-btn el-icon-delete delete"
                   @click.stop="deleteItem(row)" v-if="!row.used">删除</el-button>
        <!-- 测试用 -->

      </template>

    </ym-table-list>
    <add-type v-if="showAddition"
                      @create="create"
                      @hide="hiddenModal('showAddition')"></add-type>
      <update-type v-if="showUpdatetion"
    @update="update"
    :id="id"
    :title="'编辑类型'"
    @hide="hiddenModal('showUpdatetion')"></update-type>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import addType from './addType.vue'
import updateType from './updateType.vue'

export default {

  components: {
    YmTableList,
    updateType,
    addType
  },
  data() {
    return {
      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [{
        label: '类型名称',
        prop: 'typeName',
        align: 'center'
      },
      {
        label: '功能权限',
        prop: 'tags',
        align: 'left',
        enableColSlot: true
      },
      {
        label: '创建时间',
        prop: 'createTime',
        align: 'center',
        enableColSlot: true
      }
      ],
      pageLayout: 'prev, pager, next, jumper',
      total: 20, // 数据总数
      size: 10,
      page: 1,
      search: '', // 搜索内容
      showAddition: false, // 显示新增窗口
      showUpdatetion: false, // 显示编辑窗口
      id: '' //

    }
  },
  watch: {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.search = ''
      this.handleSearch()
    },
    // 显示新增记账单位窗口
    showAdditionFn() {
      this.showAddition = true
      this.$bus.$emit('blurScreen')
    },
    // 销毁新增记账单位窗口
    hiddenModal(type) {
      this.$bus.$emit('clearBlur')
      this[type] = false
    },
    // 处理新增类型
    create(data) {
      this.$http.ticketType.addTicketType({
        typeName: data.typeName,
        functionType: data.functionType,
        donor: data.donor,
        detail: data.detail
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hiddenModal('showAddition')
          this.handleRefresh()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    update(data) {
      this.$http.ticketType.updateTicketType({
        id: this.id,
        typeName: data.typeName,
        functionType: data.functionType,
        donor: data.donor,
        detail: data.detail
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hiddenModal('showUpdatetion')
          this.handleRefresh()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    showDetail(row) {
      this.id = row.id
      this.showUpdatetion = true
      this.$bus.$emit('blurScreen')
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      console.log(page)
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.ticketType.getTicketTypeList({
        size: this.size,
        page: this.page,
        typeName: this.search
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    delete (row) {
      this.$http.ticketType.deleteTicketType({
        id: row.id
      }).then(res => {
        if (res.data.code === 0) {
          this.getList()
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    deleteItem (row) {
      this.$confirm('确定删除，删除之后无法恢复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delete(row)
      }).catch(() => {

      })
    }
  }
}
</script>

<style lang="scss">
.tickets {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        // position: relative;
        // width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 12px;
        margin-right: 10px;
        cursor: default;
        .el-icon-close {
          // position: absolute;
          // right: 11px;
          // top: 50%;
          // margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
.tag {
  margin-right: 10px;
}
.delete{
  color:rgba(240, 107, 108, 1) !important;
}
// .tickets .el-table--border th:first-child .cell, .el-table--border td:first-child .cell{
//   padding-left: 36px;
// }
</style>
