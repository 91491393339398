<script>
import addmodal from './addType.vue'
import { deepClone } from '@/utils/deepClone.js'

export default {
  extends: addmodal,
  props: {
    id: [Number, String]
  },
  data () {
    return {
      form: {
        typeName: '',
        functionType: '普通券',
        donor: [],
        detail: []
      }
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$http.ticketType.getTicketTypeDetail({
        id: this.id
      }).then(res => {
        if (res.data.code === 0) {
          this.form.functionType = res.data.data.functionType
          this.form.donor = res.data.data.donor
          this.form.detail = res.data.data.detail
          this.form.typeName = res.data.data.typeName
          console.log(this.form)
        }
      })
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = deepClone(this.form)
          console.log(data)
          this.$emit('update', data)
        } else {
          return false
        }
      })
    },
    change (val) {

    }
  },
  watch: {
    'form.functionType'(newVal, old) {
      this.change(newVal)
    }
  }

}
</script>
